/**
 * tbl-mustache.js
 *
 * Created by Adi Dan on 4/6/15.
 *
 * The following utility is a wrapper for template engines, currently handlebars.js.
 * It automatically resolves and registers templates and partials by path when in local mode,
 * and resolves them from the global object App.Templates (precompiled) when in production mode.
 * In addition, it loads custom handlebars helpers.
 */

define('templateEngine',[
        'handlebars',
        'handlebarsHelpers'
    ],
    function (Handlebars) {
        var Engine = {};

        /**
         * Gets a handlebars template path and returns the compiled template.
         * If there is a precompiled template registered on the given path, return it. If not found (local, non-minified dev mode),
         * require the template as a module and compile it.
         *
         * @param path - path to the template
         * @returns {*}
         */
        function getTemplate(path) {
            var precompiledTemplates = window['App'] && window['App']['Templates'],
                tpl;

            if(precompiledTemplates && precompiledTemplates[path]) {
                 return precompiledTemplates[path];
            }

            tpl = require('text!templates_dir/' + path + '.hbs');

            return Handlebars.compile(tpl, {compat: true});

        }

        /**
         * Renders a handlebars template
         * @param template - path to the template
         * @param context - rendering context
         * @param [partials] - array of paths to partials
         * @returns {*} - the rendered template
         */
        Engine.render = function(template, context, partials) {
            context = context || {};

            if(partials) {
                partials.forEach(function(partial) {
                    Handlebars.partials[partial] = getTemplate(partial);
                });
            }

            var tpl = getTemplate(template);

            return tpl(context);
        };

        return Engine;
    }
);

