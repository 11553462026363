/**
 * Taboola Form  Plugin
 * 
 * Implements form validation and marshalling of disabled fields.
 * Another optional functionality of the form is to track modification of the form and warn the user 
 * before navigating from the page without submitting the changes. In order to enable this functionality,
 * the form element should be marked with the CSS class "watch-dirty". In such case, specific actions may avoid this check
 * by adding the CSS class "ignore-dirty" to the form element (for example, see on "submit" behaviour).
 */
define('taboolaForm',[
      'messages',
      'pconsole',
      'validator',
      'validatorShake'
    ],
    function(i18n){
        var methods = {
                init: function(options) {
                    var $this = $(this),
                        $serverErrors = $this.find('.validation-error.server-validation span'),
                        validatorInputEvent = options && options.validatorInputEvent ? options.validatorInputEvent : null,
                        validatorErrorInputEvent = 'keyup';

                    $this.validator({effect: 'shake', grouped: true, errorInputEvent: validatorErrorInputEvent, inputEvent: validatorInputEvent});
                    $.tools.validator.localize('en', PCONSOLE.validationMsgTranslator.defaultMessages.errors);

                    $this.on('validatorFieldFail', function() {
                        $this.removeAttr('data-submitted');
                    });


                    //If there are error messages from the server, form is dirty
                    if($serverErrors.length > 0) {
                        $this.addClass('dirty');
                    }

                    //Clean server errors once field value is changed
                    $this.on('change ' + validatorErrorInputEvent, 'input, select', function(e) {
                        var $target = $(e.target),
                            $controlGroup = $target.closest('.control-group');

                        $controlGroup.find('.validation-error.server-validation').remove();

                        if(!$controlGroup.find('.validation-error').length) {
                            $controlGroup.removeClass('error');
                        }

                    });

                    //prevent submit in case user presses "enter" when focused on a field
                    $this.on('keypress', 'input, select', function(e) {
                        if (e.keyCode===13) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    });

                    $this.on('submit', function(e) {
                        $this.addClass('ignore-dirty');
                        if (!e.isDefaultPrevented()) {
                            $this.taboolaForm('marshallDisabledFields');
                        }
                    });

                    $this.on('click', '[data-novalidate]', function() {
                        var api = $this.data("validator");

                        if (api) {
                            api.destroy();
                        }
                    });

                    /** Managing focus for composite-input */
                    // ----------------------------

                    $this.on('focus', '.composite-input input[type=text], .composite-input button', function (e) {
                        var $input = $(e.currentTarget);
                        $input.closest('.radio-label').find('input[type=radio]').prop('checked', true);
                        if (!$input.is('.composite-input input[type=text]')) {
                            $input.closest('.radio-label').find('input[type=text]').focus();
                        }
                    });

                    $this.on('click change composite-input-initial-check', '.composite-input input[type=radio]', function (e) {
                        var $input = $(e.currentTarget);
                        if ($input.is(':checked')) {
                            // Update focus
                            var $selectedLabel = $input.closest('label.radio-label'),
                                $nonSelectedLabels = $selectedLabel.siblings('label.radio-label');
                            $selectedLabel.find('input[type=text]').focus();
                            // Delete value of other inputs
                            $nonSelectedLabels.find('input[type=text]').val('');

                            // Enable required-unused
                            $selectedLabel.find('input[required-unused]').attr('required', 'required').removeAttr('required-unused');
                            // Disable required
                            $nonSelectedLabels.find('input[required]').attr('required-unused', 'required-unused').removeAttr('required');
                        }
                    });

                    $this.find('.composite-input input[type=radio]:checked').trigger('composite-input-initial-check');
                    // ----------------------------

                    $this.on('change', function(e) {
                        $(e.target).addClass('dirty')
                            .trigger('markedDirty');

                    });

                    if($this.hasClass('watch-dirty')) {
                        window.onbeforeunload = function () {
                            if(!$this.hasClass('ignore-dirty') && $('.dirty').length) {
                                return i18n.message.formNotSavedWarning();
                            }
                        };
                    }

                    PCONSOLE.preventMultipleSubmit($this);
                    return $this;
                },

                marshallDisabledFields: function() {
                    var $this = $(this),
                        disabledFields = $this.find(':disabled').not('[skip-marshall]');

                    // remove previously generated fields
                    $this.find('.generated-field').remove();

                    disabledFields.each(function(i, input) {
                        if (input.name) {
                            if ((input.type !== 'radio' && input.type !== 'checkbox' && input.type !== 'submit') || input.checked) {
                                $('<input />', {
                                    type: 'hidden',
                                    name: input.name,
                                    'class': 'generated-field',
                                    value: input.value
                                }).appendTo($this);
                            }
                        }
                    });

                    return $this;

                },

                localize: function(obj) {
                    $.tools.validator.localize('en', obj);

                    return $(this);
                }
            };

        $.fn.taboolaForm = function(method) {
    
            // Method calling logic
            if (methods[method]) {
                return methods[ method ].apply(this, Array.prototype.slice.call(arguments, 1));
            } else if (typeof method === 'object' || ! method) {
                return methods.init.apply(this, arguments);
            }
            $.error('Method ' + method + ' does not exist on jQuery.taboolaForm');
            return null;
    
        };

        $(function () {
            $('body').one('click.taboolaForm.data-api', '[data-form=taboola]', function () {
                $(this).taboolaForm();
            });
        });
});

