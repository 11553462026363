/**
 * Created by talya.v on 7/23/2014.
 */

define('authenticationMethod',[
    'messages',
    'templateEngine',
    'text!templates_dir/controls/validation-error.hbs',
    'pconsole',
    'taboolaForm',
    'panelsToggler',
    'phoneControl'
], function (i18n, TemplateEngine) {
    var $authForm = $('#twoStepAuthMethodForm'),
        $selectedAuthTypeField = $('#selectedAuthType'),
        $codeInputControl = $('#codeControlGroup'),
        $saveBtn = $('#save'),
        $selectionBtns = $('button[name="selectedAuthType-button"]'),
        $codeInputField = $('#code'),
        eventListenerChoiseButton = function (event) {
            applyIsSelected(this.value);
        }
    removeRequired = function (form) {
        var inputs = form.find($("input"));
        if (inputs) {
            inputs.removeAttr('required');
        }
    },
        uponSubmit = function () {
            if ($selectedAuthTypeField.val() === 'TFA_EMAIL_AUTH') {
                removeRequired($authForm);
            }
        },
        applyIsSelected = function (name) {
            $selectedAuthTypeField[0].value = name;
            toggleCodeSubmitControls()
        },
        toggleCodeSubmitControls = function () {
            if ($selectedAuthTypeField.val() === 'TFA_EMAIL_AUTH') {
                $saveBtn.prop('disabled', false);
                $codeInputField.hide();
                $codeInputControl.hide();
            } else if ($selectedAuthTypeField.val() === 'TFA_GOOGLE_AUTH' || $authForm.hasClass('code-sent')) {
                $saveBtn.prop('disabled', false);
                $codeInputField.prop('disabled', false);
                $codeInputField.show();
                $codeInputField.prop('required');
                $codeInputControl.show();
            } else {
                $saveBtn.prop('disabled', true);
                $codeInputField.prop('disabled', true);
                $codeInputField.prop('required');
                $codeInputControl.hide();
            }

            //every time that the visible code related inputs are "refreshed" we need to reset the value and
            // the error messages
            $codeInputField.val('');
            $('.error').removeClass('error');
            $('.validation-error').text('');
            $('.fixed-message').hide();
        },
        setupAuthMethodForm = function () {
            $selectionBtns.on('click', eventListenerChoiseButton);


            if (!$authForm.length) {
                return;
            }

            $.fn.initPhoneControl({
                $countryCodeField: $('#countryCode'),
                $phoneCodeField: $('#phoneCode'),
                $phoneNumberField: $('#phone'),
                countriesData: window['getCountriesData']()
            });

            $selectedAuthTypeField.on('change', toggleCodeSubmitControls);
            $saveBtn.on('click', uponSubmit);

            /*
             "Send" button click handler - sends the integration email
             using ajax request.
             */
            $('#sendCode').on('click', function () {
                var promise,
                    $afterCodeSend = $('.after-code-send'),
                    $phoneControl = $('#phoneControl'),

                    $loadingIndicator = $phoneControl.find('.loading-indicator'),
                    addCodeSendFailErr = function () {
                        var $errContainer = $phoneControl.find('.validation-error');

                        if (!$errContainer.length) {
                            $errContainer = $(TemplateEngine.render('controls/validation-error', {errMessage: ''}))
                                .appendTo($phoneControl.find('.controls'));
                        }

                        $errContainer.text(i18n.authenticationMethod.codeSendError());
                    };

                //before validating, we need to ignore validation of the "code" field by disabling it (we re-enable
                // after sending the ajax request)
                $codeInputField.prop('disabled', true);

                if ($authForm.data("validator").checkValidity()) {
                    $loadingIndicator.show();
                    $afterCodeSend.show();

                    promise =
                        $.post($('#applicationDomain').val() + 'tfa/auth-init', $authForm.serializeArray(), null,
                            "json");

                    promise.done(function (response) {
                        if (response.success) {
                            $authForm.addClass('code-sent');
                            toggleCodeSubmitControls();
                        } else {
                            addCodeSendFailErr();
                        }

                        $loadingIndicator.hide();
                    });

                    promise.fail(function () {
                        addCodeSendFailErr();
                        $loadingIndicator.hide();
                    });
                }

                //re-enable code field, validation finished
                $codeInputField.prop('disabled', false);
            });
        },

        initFormValidator = function () {
            if ($authForm.length) {
                $authForm.taboolaForm()
                    .taboolaForm("localize", PCONSOLE.validationMsgTranslator.authenticationMethod.errors);
            }
        };

    /*
     only the clicked submit button should have a value. This is how we know which
     action should be taken in the controller.
     */
    $authForm.on('click', 'button[type=submit], button[type=submit] i', function (e) {
        var $target = $(e.target).closest('button');
        $target.val('x422');
    });

    /* self invocation */
    $(document).ready(function () {
        if ($authForm.length) {
            setupAuthMethodForm();
            initFormValidator();

        }
    });

});

