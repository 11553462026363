define('sidebarMenu',[
    'jquery'
], function () {
    var SidebarMenu = function ( element, options ) {
            var $toggler = options.toggler,
                $closeOutsideEl = options.closeOnClickOutside;

            if ( !($toggler instanceof jQuery) ) {
                $toggler = $(options.toggler);
            }

            if ( !($closeOutsideEl instanceof jQuery) ) {
                $closeOutsideEl = $(options.closeOnClickOutside);
            }

            this.$toggler = $toggler;
            this.$closeOutsideEl = $closeOutsideEl;

            // register toggler button
            $toggler.on('click', this.toggle.bind(this));

            this._el = element;
        };

    SidebarMenu.prototype = $.extend({}, {
        toggle: function () {
            // note: can't use 'classList.toggle' cause open/close
            // has logic in them
            if (this._el.classList.contains('open')) {
                this.close();
            }
            else {
                this.open();
            }
        },
        open: function () {
            var that = this;
            that._el.classList.add('open');

            setTimeout(function () {
                // setTimeout because otherwise the listener catches the current
                // event
                document.addEventListener('click', function listener (e) {
                    // Close the menu no matter what, because you either clicked on
                    // a link or on the background, either way, there's no more need
                    // for the menu
                    if (!$(e.target).closest(that.$closeOutsideEl).length) {
                        e.preventDefault();
                        e.stopPropagation();
                        that.close();
                    }

                    document.removeEventListener('click', listener, true);
                }, true);
            }, 0);
        },
        close: function () {
            this._el.classList.remove('open');
        }
    });

    $.fn.sidebarMenu = function ( option ) {
        return this.each(function () {
            var $this = $(this),
               data,
               options = typeof option === 'object' && option;


           if (typeof option === 'string') {
               data = $this.data('sidebarMenu');
               data[option]();
           }else {
               $this.data('sidebarMenu', (data = new SidebarMenu(this, options)));
           }
        });
    };
});

