/**
 * phone-control.js
 *
 * sets a control for phone fields (country code + phone number).
 * uses intl-tel-input component to set up a dropdown with flags for the country code field
 *
 * options:
 *
 * $countryCodeField - jQuery element of the hidden field that holds the country code value that will be sent to the server (iso2, e.g un, gb, il)
 * $phoneCodeField - jQuery of the input field of the phone code (e.g. +44, +1). This field will be converted to intl-tel-input dropdown
 * phoneNumberField - jQuery of the input field that holds the phone number value
 * countriesData (optional) - array of objects containing the countries data for the country codes drop down list (in the format that intl-tel-input component expects).
 *                          If none provided, the default countries data of intel-tel-input will be used.
 *
 */

define(
    'phoneControl',[
        'pconsole',
        'intlTelInput'
    ],
    function() {
        $.fn.initPhoneControl = function(options) {

            if(options.countriesData) {
                $.fn.intlTelInput.setCountryData(options.countriesData);
            }

            options.$phoneCodeField.on('click', function(e) {
                //this makes the phone codes dropdown open when clicking on the original input field
                var $intlTelInput = $(e.target).closest('.intl-tel-input');

                $intlTelInput.find('.selected-flag').trigger('click.intlTelInput1');
                e.stopPropagation();

            }).intlTelInput({
                autoHideDialCode: false,
                autoFormat: false,
                defaultCountry: options.$countryCodeField.val().toLowerCase(),
                preferredCountries: ['us', 'gb', 'il']

            }).on('change selfTriggerChange', function(e) {
                /*
                 this will update the hidden field that is sent to the server,
                 whenever a new country is selected with the corresponding country code
                 */
                var $field = $(e.target),
                    countryCode = $field.intlTelInput('getSelectedCountryData')['iso2'].toUpperCase();

                options.$countryCodeField.val(countryCode);

            }).trigger('selfTriggerChange');

            /*
             when user focuses on the phone number field, we check if the field's value is a "masked" phone
             number (e.g. '***-**55'). If so, the whole text should be selected on focus, so that when the
             user starts to type a new value the masked value will be deleted (user may
             not "edit" a masked value, but rather re-type the whole number)
             */
            options.$phoneNumberField.on('focus', function(e) {
                var $input = $(e.target);

                if($input.val().indexOf('*') !== -1) {
                    setTimeout(function() { //use set timeout to override the browser's default behavior
                        $input.select();
                    }, 0);
                }
            });
        };
    }
);
