/**
 * Created with IntelliJ IDEA.
 * User: adi.d
 * Date: 01/07/15
 * Time: 11:28
 * To change this template use File | Settings | File Templates.
 */
define('i18nUtils',[
    'messages'
], function(i18n) {

    var i18nUtils = {};

    i18nUtils.normalizeForI18n = function(value) {
        return value.replace(/\-/g, "_").replace(/\,/g, "").replace(/\s/g, "_").replace(/&/g, "_n_").replace(/\.$/, "");
    };

    /*
     get a message from the regional translation object.
     key - a string representing the key of the message in the regional object
     base (optional) - a string representing a base key where key should be looked for
     */
    i18nUtils.getRegionalMessage = function(key, base) {
        var o = i18n,
            ptr = ((base ? base + "." : "") + key).split(".");

        while (ptr.length > 1) {
            o = o[ptr.shift()];
        }
        return o[ptr[0]];
    };

    /**
     * Try to find a value in the messages object tree. if not found or exception thrown - returns the original value (key suffix)
     * Useful when trying to translate messages for common.messages.resolved where the key suffix is the same as the English
     * translation of the message - and we do not know if the value is a name (should not be translated) or a real key (should be translated)
     * @param value
     * @param mskKeyPrefix
     * @returns {*}
     */
    i18nUtils.translateValueOrReturnOriginal = function(value, mskKeyPrefix) {
        var translatedValue;

        try {
            if (!value || typeof this.getRegionalMessage(mskKeyPrefix) !== 'object') {
                return value;
            }

            translatedValue = this.getRegionalMessage(mskKeyPrefix + '.' + i18nUtils.normalizeForI18n(value));
        } catch (e) {
            // Do nothing
        }

        return typeof translatedValue === 'function' ? translatedValue() : value;
    };

    /**
     * Gets a messages key prefix and returns all messages starting with the given prefix
     * as an array. For example, if msgsKeyPrefix = 'datepicker.dayNamesMin', and in messages file we have:
     *
     *  datepicker.dayNamesMin.1=Sun
     *  datepicker.dayNamesMin.2=Mon
     *  ...
     *
     *  The function will return: ["Sun", "Mon", ....]
     *
     * @param msgsKeyPrefix - the key prefix to look for
     * @returns {Array} - translated values as an array
     */
    i18nUtils.getTranslationsArray = function(msgsKeyPrefix) {
        var msgsObject = this.getRegionalMessage(msgsKeyPrefix);

        return Object.keys(msgsObject).map(function(i) {
            return msgsObject[i]();
        });
    };

    return i18nUtils;
});
