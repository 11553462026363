'use strict';

/**
 * Backstage require.js configuration
 */


(function () {
    require.config({
        baseUrl: '/authentication/resources/',
        waitSeconds: 30,
        paths: {
            templates_dir: 'app/templates',
            handlebars: 'components/handlebars/handlebars-v4.0.5',
            handlebarsHelpers: 'app/utils/handlebars-helpers',
            templateEngine: 'app/utils/template-engine',
            text: 'components/requirejs-text/text',
            jquery: 'components/jquery/jquery-1.11.1',
            jqueryuiPosition: 'components/jquery-ui/v-1.9.2/jquery.ui.position',
            jqueryuiDraggable: 'components/jquery-ui/v-1.9.2/jquery.ui.draggable',
            jqueryuiResizable: 'components/jquery-ui/v-1.9.2/jquery.ui.resizable',
            jqueryuiEffectblind: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-blind',
            jqueryuiEffectbounce: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-bounce',
            jqueryuiEffectclip: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-clip',
            jqueryuiEffectdrop: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-drop',
            jqueryuiEffectexplode: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-explode',
            jqueryuiEffectfade: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-fade',
            jqueryuiEffectfold: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-fold',
            jqueryuiEffecthighlight: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-highlight',
            jqueryuiEffectpulsate: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-pulsate',
            jqueryuiEffectslide: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-slide',
            jqueryuiEffecttransfer: 'components/jquery-ui/v-1.9.2/jquery.ui.effect-transfer',
            jqueryuiEffect: 'components/jquery-ui/v-1.9.2/jquery.ui.effect',
            jqueryuiCore: 'components/jquery-ui/v-1.9.2/jquery.ui.core',
            jqueryuiMouse: 'components/jquery-ui/v-1.9.2//jquery.ui.mouse',
            jqueryuiSortables: 'components/jquery-ui/v-1.9.2/jquery.ui.sortable',
            jqueryuiDatepicker: 'components/jquery-ui/v-1.9.2/jquery.ui.datepicker',
            jqueryuiDialog: 'components/jquery-ui/v-1.9.2/jquery.ui.dialog',
            jqueryuiButton: 'components/jquery-ui/v-1.9.2/jquery.ui.button',
            'jquery.ui.widget': 'components/jquery-ui/v-1.9.2/jquery.ui.widget',
            jqueryuiHashchange: 'components/jquery-hashchange-master/jquery.ba-hashchange',
            jqueryBrowser: 'components/jquery.mb.browser-master/jquery.mb.browser',
            jqueryDependsOn: 'components/dependsOn/dependsOn-1.0.0',
            jqueryFlot: 'components/flot/v-0.7/jquery.flot',
            jqueryFlotCrosshair: 'components/flot/v-0.7/jquery.flot.crosshair',
            jqueryFlotNavigate: 'components/flot/v-0.7/jquery.flot.navigate',
            jqueryMousewheel: 'components/jquerytools/mousewheel-v-3.0.6/jquery.mousewheel',
            jquerySparkline: 'components/jquery.sparkline/jquery.sparkline',
            intlTelInput: 'components/intl-tel-input-master/build/js/intlTelInput',
            bootstrap: 'modified-components/bootstrap/js/bootstrap',
            bootstrapTypeahead: 'modified-components/bootstrap/js/bootstrap-typeahead',
            acdropdown: 'modified-components/bootstrap/js/autocomplete-dropdown',
            freeTextField: 'app/plugins/list-inputs/free-text-field',
            highlighter: 'app/utils/highlighter',
            combobox: 'modified-components/bootstrap/js/bootstrap-combobox', // ??
            html2canvas: 'modified-components/html2canvas/html2canvas-modified', // ??
            modernizr: 'modified-components/modernizer/modernizr.custom.20215',
            dateUS: 'components/Datejs-all/build/date-en-US',
            moment: 'components/moment/moment',
            i18nUtils: 'app/utils/i18n-utils', // !!
            stringUtils: 'app/utils/string-utils',
            pconsole: 'app/boot/pconsole', // !!
            masterCheckbox: 'app/ui-components/master-checkbox',
            login: 'app/ui-components/login',
            authenticationMethod: 'app/ui-components/authentication-method',
            authentication: 'app/ui-components/authentication',
            passreset: 'app/ui-components/passreset', // !!
            domStorage: 'app/ui-components/dom-storage',
            validatorShake: 'app/ui-components/validator-shake-effect', // !!
            log: 'app/plugins/log',
            tabDropdown: 'app/plugins/tab-dropdown', // ??
            pwStrength: 'app/plugins/pwstrength', // !!
            queryParams: 'app/plugins/query-params',
            taboolaForm: 'app/plugins/tbl-form', // !!
            formDep: 'app/plugins/form-dependency',
            formState: 'app/plugins/form-state',
            sidebarMenu: 'app/plugins/sidebar-menu',
            excanvas: 'components/excanvasjs.js/excanvas',
            html5: 'components/html5/html5',
            validator: 'app/utils/validator-modified', // !!
            phoneControl: 'app/utils/phone-control',
            wcResponsiveSteps: 'app/plugins/wc-responsive-steps',
            comparisonBar: 'app/plugins/comparison-bar', //??
            validationMsgTranslator: 'app/utils/validation-messages-translator', // !!
            Intl: 'components/Intl.js/Intl', // !!
            l10nFactory: 'app/utils/l10n-factory', // !!
            l10n: 'app/utils/l10n', // !!
            objectUtils: 'app/utils/object-utils',
            translator: 'app/utils/translator',
            twittterTypeahead: 'components/twitter-typeahead/dist/typeahead.bundle', // ?? Ajax / Autocomplete?
            messages: 'nls/messages',
            panelsToggler: 'app/plugins/panels-toggler',
            singleSelectWithInput: 'app/plugins/single-select-with-input', // ??
            fingerprint2: 'app/ui-components/fingerprint2'
        },
        shim: {
            'tabDropdown': {
                deps: ['jquery', 'bootstrap']
            },
            'validator': {
                deps: ['jquery']
            },
            'dataTable': {
                deps: ['jquery']
            },
            'bootstrap': {
                deps: ['jquery', 'jqueryuiButton'] // jquery.ui.button has a conflict with bootstrap - both use $.fn.button
            },
            'bootstrapTypeahead': {
                deps: ['jquery']
            },
            'jqueryuiCore': {
                deps: ['jquery']
            },
            'combobox': {
                deps: ['jquery', 'bootstrapTypeahead']
            },
            'jqueryuiEffect': {
                deps: ['jquery']
            },
            'jqueryuiMouse': {
                deps: ['jquery', 'jquery.ui.widget']
            },
            'jqueryuiSortables': {
                deps: ['jquery', 'jqueryuiCore', 'jqueryuiMouse', 'jquery.ui.widget']
            },
            'jqueryuiHashchange': {
                deps: ['jquery', 'jqueryBrowser']
            },
            'jquery.ui.widget': {
                deps: ['jquery']
            },
            'jqueryuiDatepicker': {
                deps: ['jquery']
            },
            'jqueryuiDialog': {
                deps: ['jquery', 'jqueryuiCore', 'jquery.ui.widget', 'jqueryuiButton',
                    'jqueryuiDraggable', 'jqueryuiMouse', 'jqueryuiPosition', 'jqueryuiResizable' ]
            },
            'jqueryuiPosition': {
                deps: ['jquery']
            },
           'domStorage': {
                deps: ['jquery']
            },
            'jqueryuiDraggable': {
                deps: ['jquery', 'jquery.ui.widget', 'jqueryuiCore', 'jqueryuiMouse']
            },
            'jqueryuiResizable': {
                deps: ['jquery', 'jquery.ui.widget', 'jqueryuiCore', 'jqueryuiMouse']
            },
            'jqueryuiEffectblind': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectbounce': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectclip': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectdrop': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectexplode': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectfade': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectfold': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffecthighlight': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectpulsate': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffectslide': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiEffecttransfer': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryuiButton': {
                deps: ['jquery', 'jquery.ui.widget', 'jqueryuiCore']
            },
            'jqueryBrowser': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jqueryDependsOn': {
                deps: ['jquery']
            },
            'twittterTypeahead': {
                deps: ['jquery', 'templateEngine']
            },
            'jqueryMousewheel': {
                deps: ['jquery', 'jqueryuiEffect']
            },
            'jquerySparkline': {
                deps: ['jquery']
            },
            'validatorShake': {
                deps: ['jquery', 'validator']
            },
            'jqueryFlotCrosshair': {
                deps: ['jquery', 'jqueryFlot']
            },
            'jqueryFlotNavigate': {
                deps: ['jquery', 'jqueryFlot', 'jqueryFlotCrosshair']
            },
            'jqueryFlot': {
                deps: ['jquery']
            },
            'log': {
                deps: ['jquery']
            },
            'pwStrength': {
                deps: ['jquery']
            },
            'acdropdown': {
                deps: ['jquery', 'combobox']
            },
            'wcResponsiveSteps': {
                deps: ['jquery']
            }
        }
    });


    var isBrowser = !!(typeof window !== 'undefined' && navigator && document),
        isWebWorker = !isBrowser && typeof importScripts !== 'undefined';

    if(!isWebWorker) {
        require([
            'messages',
            'jquery',
            'login',
            'authenticationMethod',
            'authentication',
            'passreset'
        ], function () {

        });
    }

}());

define("app/boot/app.tmp", function(){});

