define('handlebarsHelpers',[
    'i18nUtils',
    'handlebars'
    ], function(i18nUtils, Handlebars) {

    /**
     * Messages helper - get a message from the I18n object
     *
     * <pre>
     *  {{message "code" args* [default="default message"] }}
     * </pre>
     *
     * Where:
     * <ul>
     * <li>code: String literal. Required.</li>
     * <li>args: Object. Optional</li>
     * <li>default: A default message. Optional.</li>
     * </ul>
     */
    Handlebars.registerHelper("message", function(code) {
        var msg,
            options = arguments[[].slice.call(arguments).length - 1],
            msgParams = [].slice.call(arguments, 1, arguments.length - 1);

        try {
            msg = i18nUtils.getRegionalMessage(code);
        } catch (e) {
            //do nothing
        }
        return typeof msg === 'function' ? msg(msgParams) : options.hash.default;
    });

    /**
     * Defaults to a given defaultValue in case the given value is empty / empty array
     */
    Handlebars.registerHelper("defaultIfEmpty", function(value, defaultValue) {
        if(typeof value === 'undefined' || value === null || value === "" || !value.length) {
            return defaultValue;
        }

        return value;
    });

    /**
     * Concatenates string arguments to a single string
     */
    Handlebars.registerHelper("join", function() {
        var separator = arguments[[].slice.call(arguments).length - 2],
            parts = [].slice.call(arguments, 0, arguments.length - 2);
        return parts.join(separator);
    });

    /**
     * Looks for a message key prefix in the context and if not found returns
     * the default prefix.
     */
    Handlebars.registerHelper("concatMessageKeyPrefix", function(key) {
        var options = arguments[[].slice.call(arguments).length - 1],
            messageNS = options.data["messageNS"] || "application.symbol.resolved";

        return messageNS + "." + key;
    });
    
});
