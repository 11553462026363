/**
 * login.js
 * publisher console publishers.
 *
 * @author Adi Dan
 * Octber 1, 2013
 * Copyright 2013, taboola.com
 */
define('login',[
        'messages',
        'pconsole',
        'taboolaForm',
        'pwStrength',
        'fingerprint2'
    ], function (i18n, pconsole, taboolaForm, pwStrength, Fingerprint2) {

    /* self invocation */
    var $passwordChangeForm    = $('#passwordChangeForm'),
        $passwordRestForm      = $('#passwordRestForm'),
        $googleSignInLink      = $('#google-signin-btn'),
        $emailPassBtn          = $('#emailPassButton'),
        $oneTimeLinkButton     = $('#oneTimeLinkButton'),
        $passLoginForm         = $('#loginForm'),
        $oneTimeLinkForm       = $('#oneTimeLinkForm'),

        setupPasswordResetForm = function () {
            var ue = $('.pass-reset-form');


            if (!ue.length) {
                return;
            }
            $('input[type=password][data-strength]').pwStrength({
                                                                    levels: i18n.user.passwordStrengthLevels
                                                                });

        },

        getRecaptchaKey = function() {
            var element = $('#recaptchaKey');
            if (element.length >0){
                return element[0].value;
            }
            return null;
        },

        recaptchaKey           = getRecaptchaKey(),

        initFormValidator      = function () {
            var ue = $('.pass-reset-form');

            if (ue.length) {
                ue.taboolaForm().taboolaForm("localize", PCONSOLE.validationMsgTranslator.user.errors);
            }
        },

        addFingerPrintInput = function () {
            Fingerprint2.getV18(function (murmur) {
                if (murmur != null){
                    var form = getForm();
                    if (form != null){
                        input = document.createElement("input");
                        input.setAttribute("type", "hidden");
                        input.setAttribute("name", "fp");
                        input.setAttribute("value", murmur);
                        form.prepend(input);
                    }
                }
            });
        },

        getForm = function(){
            var forms = ["loginForm", "passwordRestForm", "twoStepAuthForm", "oneTimeLinkForm"];
            var form = null;
            for (i=0; i<forms.length; i++){
                if (form != null){
                    break;
                }
                form = document.getElementById(forms[i]);
            }
            return form;
        },

        setFingerPrint = function() {
            if (window.requestIdleCallback) {
                requestIdleCallback(addFingerPrintInput)
            } else {
                setTimeout(addFingerPrintInput, 500)
            }
        },

        addEventListener = function(){
            var form = getForm();
            form.addEventListener('submit', addCaptcha);
        },

        addFormSelectorEventListener = function (){
            emailPassButton = document.getElementById("emailPassButton");
            emailPassButton.addEventListener('click', onLoginTypeClick);
            oneTimeLinkButton = document.getElementById("oneTimeLinkButton");
            oneTimeLinkButton.addEventListener('click', onLoginTypeClick);

        },

        addCaptcha = function(event){
            event.preventDefault();
            var form = getForm();
            grecaptcha.ready(function(token) {
                grecaptcha.execute(recaptchaKey, {action: 'submit'})
                    .then(function(token){
                        addToken(token, form);
                    });
            });
        },

        addToken = function(token, form) {
            input = document.createElement("input");
            input.setAttribute("type", "hidden");
            input.setAttribute("name", "recaptcha");
            input.setAttribute("value", token);
            form.appendChild(input);
            form.submit();
        },
        onLoginTypeClick = function (event){
            var isSelected = this.id === "emailPassButton";
            $emailPassBtn[0].setAttribute("selected",isSelected);
            $passLoginForm[0].setAttribute("selected", isSelected);
            $oneTimeLinkButton[0].setAttribute("selected", !isSelected);
            $oneTimeLinkForm[0].setAttribute("selected", !isSelected);
        },
        redirectToGoogleSignIn = function (event) {
            if (!this.getAttribute('applied')) {
                event.preventDefault();
                var targetUrl = new URL($googleSignInLink[0].href),
                    username = $('#j_username'),
                    rememberMe = $('.remember-me-checkbox'),
                    params = targetUrl.searchParams;
                if (rememberMe.length > 0){
                    rememberMe.each(function (){
                        if (this.checked){
                            params.set('state', params.get('state') + '&_spring_security_remember_me=true');
                            return false;
                        }
                    });
                }
                if (username.length > 0 && username[0].value && username[0].value.length > 0) {
                    params.set('login_hint', username[0].value);
                }
                this.href = targetUrl.href;
                this.setAttribute("applied", "true");
                this.click();
            }
        }

        $(document).ready(function () {
            if (recaptchaKey){
                addEventListener();
            }
            if ($('#signin').length){
                addFormSelectorEventListener();
            }
            setFingerPrint(Fingerprint2);
            // avoid invocation in non login pages (e.g. password change...)
            if ($('#signin').length && ($passwordChangeForm.length || $passwordRestForm.length)) {
                setupPasswordResetForm();
                initFormValidator();
            }
            if ($googleSignInLink.length > 0){
                $googleSignInLink.on('click', redirectToGoogleSignIn);
            }
        });

    }
);
