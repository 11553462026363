/**
 * authentication.js
 *
 *
 * @author Yoni Felebrg
 * June 26, 2014
 * Copyright 2014, taboola.com
 */
define('authentication',[
    'pconsole',
    'taboolaForm'
], function() {
    var $tsa = $('#twoStepAuthForm');
    function initFormValidator(){
        if ($tsa.length) {
            $tsa.taboolaForm();
        }
    }
    /* self invocation */
    $(document).ready(function() {
        if ($tsa.length){
            initFormValidator();
        }
    });

});

