/**
 * This is form validation effect which is used by the backstage.
 * It is responsible for "shaking" the invalid inputs on validation errors.
 * Requires jquerytools's validator plugin.
 * @author Adi Dan
 * June 11, 2012
 * Copyright 2012, taboola.com
 */

define('validatorShake',[
        'l10n',
        'messages',
        'templateEngine',
        'text!templates_dir/controls/validation-error.hbs',
        'pconsole',
        'validator'
    ], function (l10n, i18n, TemplateEngine) {
        /**
         * Check if the date is in the required format and whether it is a real date (e.g. not 06/45/2012)
         */
        var isValidDateStr               = function (dateStr) {
            // non valid strings will return null
            return !!getDateFromStr(dateStr);
        },
            /**
             * Validated the date value is correct - compared with another date (before or after)
             * @param validation The Validator object (this)
             * @param input the field triggering the validation
             * @param value what we validate
             * @param shouldBeBefore Are we expectng the date to be before the referenced one - or after.
             * @returns true if valid, error message otherwise
             */
            validateDateValue            = function (validator, input, value, shouldBeBefore) {
                var names,
                    refField,
                    refFieldLabel = '',
                    refVals,
                    currentVal,
                    result        = true,
                    fieldName = shouldBeBefore ? 'date-before' : 'date-after';

                if (input.attr("disabled") || !value || !isValidDateStr(value)) {
                    return true;
                }

                var dataObj = buildReferenceObject(validator, input, fieldName);
                Object.keys(dataObj).forEach(function (name) {
                    var refVal,
                        refField = dataObj[name],
                        isValid;
                    if (refField) {
                        refVal = getDateFromStr(refField.value);
                        currentVal = getDateFromStr(input.val());
                    }

                    if (!(isNaN(currentVal) || isNaN(refVal))) {
                        isValid = shouldBeBefore ? currentVal <= refVal : currentVal >= refVal;

                        if (!isValid) {
                            refFieldLabel = refFieldLabel ? refFieldLabel + '\' ' + i18n.applicationSymbols.AND() + ' \'' + refField.label : refField.label;
                        }
                        result = result && isValid;
                    }
                });

                return result ? true : [refFieldLabel];
            },

            /**
             * Return a date object from a string in the current locale's format
             */

            getDateFromStr               = function (dateStr) {
                return Date.parseExact(dateStr, [i18n.date.fieldFormat.format(), i18n.date.fieldFormatShort.format()]);
            },

            /**
             * Get the value from a referenced field / element, used for comparison with the value of the validated field
             * Returns JSON:
             * {"value": "value", "label": "label", "dirty": T/F}
             */
            getReferencedFieldDetails    = function (validator, refName, staticValue) {
                var refField = validator.getInputs().filter("[name='" + refName + "'], [id='" + refName + "']"),
                    refFieldLabel,
                    refFieldValue,
                    refFieldDirty,
                    $controlsGroup;

                if (refField.length) { //look for refrence field in the form (normal form validation)
                    $controlsGroup = refField.closest(".control-group");
                    if ($controlsGroup.length) {
                        refFieldLabel = refField.attr('data-label') || validator.getFieldLabel(refField) || refName;
                        refFieldValue = refField.val();
                        refFieldDirty = refField.hasClass('dirty');
                    }
                } else if (staticValue) { //look for reference field in the report (used by popup-cell-editor)
                    var columnHeader = $('th a[href=#' + refName + ']');
                    refFieldLabel = columnHeader.length ? ($.trim(PCONSOLE.getInnerText(columnHeader[0])) || refName) : refName;
                    refFieldValue = staticValue;
                    refFieldDirty = false;
                }

                return refFieldLabel && {"label": refFieldLabel, "value": refFieldValue, "dirty": refFieldDirty};
            },

            /**
             * Builds a data object that contains values of all fields referred in a given attribute.
             * For example: let's say we have some-attribute="field1|field2" on input $input.
             * By calling buildReferenceObject(validator, $input, "some-attribute")
             * we'll get the following object:
             * {
             *      field1: {
             *          label: field1-label,
             *          value: field1-value,
             *          dirty: isDirty?
             *      },
             *      field2: {
             *          label: field2-label,
             *          value: field2-value,
             *          dirty: isDirty?
             *      }
             * }
             * Note: this supports inline editing as well as form editing.
             * @param validator
             * @param input
             * @param attrName attribute that holds all field names
             * @param includeSelf (optional) should we include also the input field itself.
             * @returns {Object} with field values
             */
            buildReferenceObject = function (validator, input, attrName, includeSelf) {
                var names,
                    refVals,
                    dataObj = {};
                names = input.attr(attrName);
                refVals = input.attr("data-" + attrName + "-val");
                if (names) {
                    var namesArr   = names.split('|'),
                        refValsArr = refVals && refVals.split('|');

                    //go over each field mentioned in the attributes
                    namesArr.forEach(function(name, i) {
                        var refValStr = refValsArr && refValsArr.length > i ? refValsArr[i] : null,
                            refField;

                        refField = getReferencedFieldDetails(validator, name, refValStr);
                        dataObj[name] = refField;
                    });
                }
                if (includeSelf && input && input.attr('name')) {
                    dataObj[input.attr('name')] = {
                        "label": '',
                        "value": input.val(),
                        "dirty": input.hasClass('dirty')
                    };
                }
                return dataObj;
            },

            removeClientValidationErrors = function ($controlGroup) {
                $controlGroup.find(".validation-error").not('.server-validation').remove();

                if (!$controlGroup.find('.validation-error').length) {
                    $controlGroup.removeClass('error');
                }
            },
            getFirstErrorOnPage          = function () {
                var firstErrorOnPage = null;

                $('.validation-error').each(function () {
                    var $this = $(this);

                    if ($this.is(':visible') && $.trim($this.text())) {
                        firstErrorOnPage = $this.closest('.controls');
                        return false;
                    }
                });

                return firstErrorOnPage;
            };


        $(document).ready(function () {

            var errCount = $('#serverErrorCount');

            // When we have evidence of a server error ... display a message
            if (errCount.length && errCount.val()) {
                $().fixedMessage(i18n.validation.errors(), 'error');
            }

            $.tools.validator.addEffect("shake", function (errors, event) {

                // get form
                var form           = $(this.getForm()),
                    $controlGroups = form.find('.control-group'),
                    validator      = this;

                //handle single fields validation (no form)
                if(!$controlGroups.length) {
                    $controlGroups = $(event.target).closest('.control-group');
                }

                $controlGroups.each(function () {
                    removeClientValidationErrors($(this));
                });

                // add errors
                $.each(errors, function (index, error) {
                    var input         = error.input,
                        controls      = input.closest(".controls"),
                        controlsGroup = controls.closest(".control-group"),
                        firstErrOnPage,
                        scrollerElem;
                    $().fixedMessage(i18n.validation.errors(), 'error');

                    //add the error class
                    controlsGroup.addClass("error");

                    //add error messages
                    $.each(error.messages, function (errIndex, errMessage) {

                        var label = input.attr('data-label') || validator.getFieldLabel(input);

                        errMessage = errMessage.replace(/\{field-name\}/g, input.attr("name")).replace(/\{field-value\}/g, input.val()).
                            replace(/\{field-label\}/g, label);

                        controls.append(TemplateEngine.render('controls/validation-error', {
                            errMessage: errMessage,
                            elId: (input.attr("id") || input.attr("name")) + errIndex
                        }));

                    });

                    /*
                     scroll and focus on input, only if this is the first invalid control on the page.
                     This condition is meant to prevent situation where this validator overrides
                     scrolling by other validation methods (e.g. server errors, table errors, etc.)
                     */
                    if (index === 0) {
                        firstErrOnPage = getFirstErrorOnPage();

                        if (firstErrOnPage && firstErrOnPage.is(controls)) {
                            if (input.is(":hidden")) {
                                scrollerElem = input.closest(".controls");
                            } else {
                                scrollerElem = input;
                            }
                            scrollerElem.scrollToElement();
                            // avoid focus on the input if the error is originated from an input blur otherwise user gets stuck in the input
                            if (!input.hasClass('date-picker') && (event && event.originalEvent && event.originalEvent.type !== "blur")) {
                                input.eq(0).focus();
                            }
                        }
                    }
                    input.trigger('validatorFieldFail');
                });

            }, function (inputs) {
                /*
                 * If validation is performed per input (not only on submit, we would like to remove the error message
                 * in case that the field is valid upon the next validation event (e.g. keyup, blur). otherwise, do nothing
                 */
                if (this.getConf().errorInputEvent) {
                    inputs.each(function (index, input) {
                        var $input        = $(input),
                            $controlGroup = $input.closest(".control-group");

                        removeClientValidationErrors($controlGroup);
                    });

                    //no more validation errors - no need for "please fix errors" fixed message
                    if (!getFirstErrorOnPage()) {
                        $('.fixed-message .alert-error').remove();
                    }
                }

            });

            /**
             * Generic validators
             */
                // Validate only if value is equal to the given attribute parameter
            $.tools.validator.fn("[value-should-be]", function (input) {
                var whatValueShouldBe = input.attr('value-should-be');

                return whatValueShouldBe ? input.val() === whatValueShouldBe : true;
            });


            //validator for input fields - ensures that the value length is not larger than specified
            $.tools.validator.fn("[maxlength]", function (input, value) {
                var maxLength = input.attr("maxlength");

                if (input.attr("disabled") || !value || isNaN(maxLength)) {
                    return true;
                }

                return value.length <= maxLength ? true : [maxLength];
            });

            //validator for input fields - ensures that the value length is not smaller than specified
            $.tools.validator.fn("[minlength]", function (input, value) {
                var minLength = input.attr("minlength");

                if (input.attr("disabled") || !value || isNaN(minLength)) {
                    return true;
                }

                return value.length >= minLength ? true : [minLength];
            });

            //validator for comma separator enabled number fields - ensures that the value is indeed a number
            $.tools.validator.fn("[data-type*=commanum]", function (input, value) {
                if (input.attr("disabled") || !value) {
                    return true;
                }
                return !isNaN(this.parseNumber(value));
            });

            //validator for number fields (not comma separator enabled) - ensures that the value is indeed a number
            $.tools.validator.fn("[data-type*=simplenum]", function (input, value) {
                if (input.attr("disabled") || !value) {
                    return true;
                }
                return !isNaN(value);
            });

            //validator for number fields - ensures that the value is a whole number
            $.tools.validator.fn("[data-type*=wholenum]", function (input, value) {
                var numValue;

                if (input.attr("disabled") || !value) {
                    return true;
                }
                numValue = this.parseNumber(value);
                return !isNaN(numValue) && !(numValue % 1);
            });


            //validator for input fields that cannot contain spaces
            $.tools.validator.fn("[data-type*=nospace]", function (input, value) {
                var spaceRegex = /\s+/;

                if (input.attr("disabled") || !value) {
                    return true;
                }
                return !spaceRegex.test(value);
            });

            //validator for checkboxes that must have at least one checkbox selected.
            $.tools.validator.fn("[checkbox-required]", function (input) {
                var group            = input.data('group'),
                    $groupCheckboxes = $('[data-group=' + group + ']'),
                    isValid          = false;

                $groupCheckboxes.each(function () {
                    if ($(this).is(':checked')) {
                        isValid = true;
                    }
                });
                return isValid;
            });

            //validator for password strength
            $.tools.validator.fn("[data-strength]", function (input) {
                var stength = input.attr('data-strength');

                return stength > 1 || !input.val();
            });

            //validator for input with a value that should be equal to the value of another field
            $.tools.validator.fn("[data-equals]", function (input) {
                var equalsInput = $("[name=" + input.attr('data-equals') + "]");

                return !equalsInput.val() || !input.val() || equalsInput.val() === input.val();
            });

            //validator for text that includes single/double quotes - makes sure that open quotes are closed
            $.tools.validator.fn('[validate-closed-quotes]', function (input, value) {
                var singleQuotesMatches = value && value.match(/'/g),
                    doubleQuotesMatches = value && value.match(/"/g),
                    isValid             = true;

                if (!singleQuotesMatches && !doubleQuotesMatches) {
                    return true;
                }

                if (singleQuotesMatches && singleQuotesMatches.length % 2 !== 0) {
                    isValid = false;
                }

                if (doubleQuotesMatches && doubleQuotesMatches.length % 2 !== 0) {
                    isValid = false;
                }

                return isValid;
            });

            //validator for css font property - makes sure that font name with space is enclosed with single quote
            $.tools.validator.fn('[validate-css-font]', function (input, value) {
                var isValid = true;

                if (value !== undefined && value !== null && value.trim() !== '') {
                    value.split(',').forEach(function (font) {
                        font = font.trim();

                        // if contains space
                        if (/\s/g.test(font)) {
                            // check that name is in single/double quotes
                            if (!/^'.*'$/g.test(font) &&
                                !/^".*"$/g.test(font)) {
                                isValid = false;
                            }
                        }
                    });
                }

                return isValid;
            });

            
        });

    }
);


