/**
 * pconsole.js
 * publisher console main object.
 *
 * @author Effie Nadiv
 * 2/17/2011
 * Copyright 2011, taboola.com
 */
define('pconsole',[
    'messages',
    'i18nUtils',
    'stringUtils',
    'modernizr',
    'bootstrap',
    'acdropdown',
    'sidebarMenu',
    'html5',
    'validationMsgTranslator'
], function (i18n, I18nUtils) {
    window.PCONSOLE = window.PCONSOLE || {};

    PCONSOLE.i18nUtils = I18nUtils;

    var scrollableElement = function () {
                var i,
                    el,
                    $scrollElement,
                    isScrollable,
                    argLength = arguments.length;

                for (i = 0; i < argLength; i++) {
                    el = arguments[i];
                    $scrollElement = $(el);

                    if ($scrollElement.scrollTop()> 0) {
                        return el;
                    }

                    $scrollElement.scrollTop(1);
                    isScrollable = $scrollElement.scrollTop() > 0;
                    $scrollElement.scrollTop(0);
                    if (isScrollable) {
                        return el;
                    }
                }

                return [];
        };

    window.debugLevel = 3; // debug -> 3, production -> 0
    window.reportPageSize = 50;
    window.sortSingleLine = true;
    window.disableEmptyTabs = false;

    $.ajaxSetup({cache: false});
    
	$(function() {
		var token = $("meta[name='_csrf']").attr("content"),
			header = $("meta[name='_csrf_header']").attr("content");

        PCONSOLE.csrf = {
            token: token,
            header: header
        };
		
		$(document).ajaxSend(function(e, xhr) {
			xhr.setRequestHeader(header, token);
		});
	});
    
    (function() {
        var pairs = window.location.search.substring(1).split( "&" );

        PCONSOLE.parameters = {};
        $.each(pairs, function(i, value) {
            var a = value.split('=');

            PCONSOLE.parameters[a[0]] = a[1];
        });
    }());

    PCONSOLE.currentUserHasPermission = function(permission) {
        return PCONSOLE.config.user.permissions.indexOf(permission) !== -1;
    };

    // does element appear in the visible part of the screen? 
    $.fn.isOutOfView = function(isTopOnly) {
        var outOfView = true,
            parentY = $('html').scrollTop() || $('body').scrollTop(),
            parentH = document.documentElement.clientHeight;

        this.each(function() {
            var $this = $(this),
                elementY = $this.offset().top,
                elementH = $this.height();

                if ( !((!isTopOnly && (elementY + elementH) > (parentY + parentH)) || (elementY < parentY)) ) {
                    outOfView = false;
                }
        });
        return outOfView;

    };

    $.fn.blink = function() {
        var $this = $(this);

        (function _blink(i) {
            $this.toggleClass('reverse');
            i -= 1;
            if (i) {
                setTimeout(function() {
                    _blink(i);
                }, 500);
            }
        }(2));
    };
    

    $.fn.resetForm = function() {
        return this.each(function() {
            var $this = $(this);

            $this.find('.validation-error').remove();
            $this.find('.alert.alert-error').remove();
        });
    };
    
    
    $.fn.scrollToElement = function(callback) {
        var $target = $(this),
            targetOffset = $target.offset().top - 50,
            se = scrollableElement('html', 'body');

        if ($target.length ) {
            if ($target.isOutOfView()) {
                $(se).animate({scrollTop: targetOffset}, 1000, 'swing', callback);
            } else if (callback !== undefined) {
                callback();
            }
             
        }
    };

    $.fn.insertAtIndex = function($item, i) {
        var $container = $(this);
        if(i === "0" || i===0) {
            $container.prepend($item);
            return;
        }
        $($container.children()[i-1]).after($item);
    };


    // mode: normal, error, success, info
    $.fn.fixedMessage = (function() {
        var $messegeDiv = $('<div></div>', {"class": 'fixed-message'}).appendTo("body").hide(),
            closeTimer,
            callbackFunc,
            defualtDuration = 10000,
            clearPrevMessage = function() {
                clearTimeout(closeTimer);
                closeTimer = null;
                if (callbackFunc) {
                    callbackFunc();
                }
                callbackFunc = null;
            },
            autoCloseMessage = function() {
                $messegeDiv.fadeOut('slow', function() {
                    $messegeDiv.children('div').html('');
                    clearPrevMessage();
                });
            };


        return function(content, mode, duration, callback) {
            $messegeDiv.hide();

            if (closeTimer) {
                clearPrevMessage();
            }
            callbackFunc = callback;
            //override message display duration - used for tests. the override should not be used in case that value is "0" (meaning, hide)
            duration = duration === 0 ? duration : (getFixedMessageDurationOverride() || duration);
            if (duration !== 0) {
                $messegeDiv.html('<div>' + content + '<a class="close" data-dismiss="alert" href="#">&times;</a></div>').children('div').attr("class", "alert alert-" + (mode || 'normal'));
                $messegeDiv.css('margin-left', 0 - $messegeDiv.width() / 2).fadeIn('slow');

                //duration = -1 means do not auto-hide message
                if (duration !== -1) {
                    closeTimer = setTimeout(function() {
                        autoCloseMessage();
                    }, duration || defualtDuration);
                }
            }
        };
    })();
    
    var initPlaceHolders = function() {
        var $placeholders = $('[placeholder]');

        $placeholders.focus(function() {
            var input = $(this);
            if (input.val() === input.attr('placeholder')) {
                input.val('');
                input.removeClass('placeholder');
            }
        }).blur(function() {
            var input = $(this);
            if (input.val() === '' || input.val() === input.attr('placeholder')) {
                input.addClass('placeholder');
                input.val(input.attr('placeholder'));
            }
        }).blur();
        $placeholders.parents('form').submit(function() {
            $(this).find('[placeholder]').each(function() {
                var input = $(this);
                if (input.val() === input.attr('placeholder')) {
                    input.val('');
                }
            });
        });

    };

    var $applicationDomain = $("#applicationDomain"),
        $applicationPublisherId = $("#applicationPublisherId");

    PCONSOLE.HL_PARAMS_SEPARATOR = $("#hlParamsSeparator").val();
    PCONSOLE.AJAX_BASE_URL = $applicationDomain.val() + 'ajax/' + $applicationPublisherId.val();
    PCONSOLE.AJAX_CRUD_URL = PCONSOLE.AJAX_BASE_URL + '/crud';
    PCONSOLE.AJAX_UPLOAD_URL = $applicationDomain.val() + 'upload';
    PCONSOLE.CLOUD_UPLOAD_URL = PCONSOLE.AJAX_UPLOAD_URL + '/cloud-upload';
    PCONSOLE.APP_PUB_URL = $applicationDomain.val() + $applicationPublisherId.val();

    /**
     * Iterates thru a node descendants
     * @param node - item to iterate thru its childeren
     * @param func - a function to call against each node
     */
    PCONSOLE.domWalker = function domWalker(node, func) {
        func(node);
        node = node.firstChild;
        while (node) {
            domWalker(node, func);
            node = node.nextSibling;
        }
    };
    PCONSOLE.childNodeWalker = function (node, func) {
        var children = Array.prototype.slice.call(node.childNodes);

        func(node);

        children.forEach(function (child) {
            PCONSOLE.childNodeWalker(child, func);
        });
    };

    /**
     * returns the textual content of a DOM node
     * @param {HTMLElement} el - DOM node
     * @returns {string} - content text
     */
    PCONSOLE.getInnerText = function (el) {
        if (typeof(el.textContent) !== 'undefined'){
            return el.textContent;
        }
        if (typeof(el.innerText) !== 'undefined'){
            return el.innerText;
        }
        if (typeof(el.innerHTML) === 'string'){
            return el.innerHTML.replace(/<[^<>]+>/g, '');
        }

        return '';
    };
    
    //prevent multiple submitting of form on multiple submit button clicks
    PCONSOLE.preventMultipleSubmit = function(form) {
        form.on('submit', function(e) {
            if (!e.isDefaultPrevented()) {
                if(!form.attr('data-submitted')) {
                    form.attr('data-submitted', true);
                }else{
                    e.preventDefault();
                    e.stopPropagation();
                    $('[type=submit]').addClass('disabled');
                }
            }
        });
    };

    PCONSOLE.handleDatePickerAboveInput = function(input, inst) {
        var offset = {left: $.datepicker._findPos(input)[0], top: $.datepicker._findPos(input)[1] + input.offsetHeight},
            isFixed = false,
            $input = $(input);

        inst.dpDiv.attr('data-name', $input.attr('name'));

        $input.parents().each(function() {
            isFixed |= $(this).css('position') === 'fixed';
            return !isFixed;
        });


        inst.dpDiv.css({position: 'absolute', display: 'block', top: '-1000px'});
        $.datepicker._updateDatepicker(inst);
        offset = $.datepicker._checkOffset(inst, offset, isFixed);

        if($input.offset().top > offset.top) {
            inst.dpDiv.addClass('aboveInput');
        }else{
            inst.dpDiv.removeClass('aboveInput');
        }
    };

    PCONSOLE.showPendingMessage = function(duration) {
        var hl = location.search.replace(/^.+?hl=([^&#]+).*/, '$1'),
            a = hl && hl.split(PCONSOLE.HL_PARAMS_SEPARATOR),
            _decodeComponent = typeof decodeURIComponent === "function" ? decodeURIComponent : unescape,
            verifyTtl = function(stamp) {
                return stamp > $('#serverTime').val();
            };

        if (a && a.length && verifyTtl(+a[4])) {
            $().fixedMessage( (i18n.fixMessage[a[3]]()).supplant({name: _decodeComponent(a[2])}),
                a[5] && a[5] === '1' ? 'success' :
                a[5] && a[5] === '2' ? 'error' : '',
                duration);
        }
    };

    function getFixedMessageDurationOverride() {

        return parseInt($('#fixedMessageDurationOverride').val(), 10) || 0;
    }

    PCONSOLE.getRandomString = function() {
        return Math.floor(Math.random() * 2147483648).toString(36) +
            Math.abs(Math.floor(Math.random() * 2147483648) ^ (new Date())).toString(36);
    };

    // escape field ids that contain period (".") for safe usage in jquery selectors
    // (field ids with periods may be generated by velocity when an entity has nested objects)
    PCONSOLE.getEscapedFieldId = function(fieldId) {
        return fieldId.replace(/\./g, '\\.');
    };

    PCONSOLE.getGlobalObjectPropertyByKey = function(key) {
        var o = window,
            ptr = ("" + key).split(".");

        while (ptr.length > 1) {
            o = o[ptr.shift()];
        }
        return o[ptr[0]];
    };

    function updateRadioButtonsFromInput($root) {
        /*
         Make sure that the selected radio-like button is the one having the
         same value as in the related hidden field
         */
        $root.find('button.radio-like').each(function () {
            var $btn = $(this),
                hiddenFieldName = $btn.attr('data-name'),
                hiddenField = $('[name="'+hiddenFieldName+'"]');

            if(hiddenField.val()) {
                if(hiddenField.val() === $btn.val()) {
                    $btn.addClass('active');
                }else{
                    $btn.removeClass('active');
                }
            }
        });
    }

    $(document).ready(function($) {
        var $body = $('body');
        // init tooltips
        $('.help-invite.bottom').tooltip({placement: 'bottom', html: true});
        // 'container:body' added to '.help-invite.left-bottom' for chart-legend tooltips (so they won't overflow the .legend-container element.
        $('.help-invite.left-bottom').tooltip({placement: 'left-bottom', html: true, container: 'body'});
        $('.help-invite.right').tooltip({placement: 'right', html: true});

        // make sure help invites are not clickable
        $('a.help-invite').on('click', function(e) {
            e.stopPropagation();
            e.preventDefault();
        });

        $body.on('click', 'a[disabled]', function(e) {
            e.preventDefault();
        });

        /* Handler for radio-like buttons - updates the related hidden field
            with the value of the clicked button.
         */
        $body.on('click', 'button.radio-like', function(e) {
            var $btn = $(e.target).closest('button.radio-like'),
                hiddenFieldName = $btn.attr('data-name');

            $('[name="'+hiddenFieldName+'"]').val($btn.val()).trigger('change');
        });

        updateRadioButtonsFromInput($('form'));

        $('.btn-group ~ input[type=hidden]').on('selfTriggerChange', function(e) {
            updateRadioButtonsFromInput($(e.currentTarget).parent());
        });

        if(!Modernizr.input.placeholder){
            initPlaceHolders();
        }

        $('#sidebar-menu').sidebarMenu({
            toggler: 'header nav .btn-side-menu',
            closeOnClickOutside: '.main-nav'
        });


        if(PCONSOLE.parameters['passchanged'] === 'true' && parseInt(PCONSOLE.parameters['tim'], 10) > parseInt($('#serverTime').val(), 10)) {
            $().fixedMessage(i18n.fixMessage.passChangeSuccess(), 'success');
        }

    });

    return PCONSOLE;
});

