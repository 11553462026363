/**
 * Password Strength Meter
 *
 * jQuery plugin
 *
 * Dependencies: jquery
 *
 * 22 Apr 2013
 * Copyright 2013, taboola.com
 */

define('pwStrength',[
    'text!templates_dir/pwstrength/meter.hbs'
], function (PWStrengthTemplate) {
    'use strict';

    var methods = {
            init: function(options) {
                var $this = this;

                this.data('pwStrength', {
                    options: options
                });

                // Inject the pwstrength meter after the element
                $this.after(PWStrengthTemplate);

                this.on("keyup", function(e) {
                    var levels   = $this.data("pwStrength").options &&
                                   typeof $this.data("pwStrength").options.levels === 'object' &&
                                   Object.keys($this.data("pwStrength").options.levels).length === 5 ?
                                        $this.data("pwStrength").options.levels :
                                        [" Too short", "Weak", "Medium", "Strong", "Very strong"],
                        $pwCont                   = $this.next('.pw-strength'),
                        $pwStrengthDesc           = $pwCont.find('.pw-strength-description'),
                        $pwStrengthMeterContainer = $pwCont.find('.pw-strength-meter-container'),
                        $pwStrengthMeter          = $pwCont.find('.pw-strength-meter'),
                        password = $(e.target).val(),
                        score    = -1,
                        maxScore = 4;

                    if(password.match(/[a-z]/)) {score++;}

                    if(password.match(/[A-Z]/)) {score++;}

                    if(password.match(/\d+/)) {score++;}

                    if(password.match(/.[!,@#$%^&*?_~()-]/)) {score++;}

                    if(password.length < 6) {
                        score = 0;
                    }
                    else {
                        score++;
                        if(password.length >= 9 && score < maxScore) {
                            score++;
                        }
                        if(password.length >= 12 && score < maxScore) {
                            score++;
                        }
                        if(password.length >= 15 && score < maxScore) {
                            score++;
                        }
                    }

                    $pwStrengthDesc.text('').text(typeof levels[score] === 'string' ? levels[score] : levels[score]());
                    $pwStrengthMeterContainer.show();
                    $pwStrengthMeter.removeClass().addClass('pw-strength-meter').addClass('strength' + score);
                    $this.attr('data-strength', score);
                });

            }
    };

    $.fn.pwStrength = function (method) {
        // Method calling logic
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }
        $.error('Method ' + method + ' does not exist on jQuery.pwStrength');
    };

});

