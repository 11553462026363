define('l10n',[
    'l10nFactory'
], function (L10nFactory) {
    return L10nFactory.newInstance({
        appDomain: document.getElementById('applicationDomain').value,
        appLocale: document.getElementById('applicationLocale').value,
        decimalSymbol: document.getElementById('decimalSeparator') && document.getElementById('decimalSeparator').value,
        digitGroupingSymbol: document.getElementById('numberGroupingSeparator') && document.getElementById('numberGroupingSeparator').value
    });
});

