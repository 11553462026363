/**
 * passreset.js
 * reset password.
 *
 */
define('passreset',[
    'messages',
    'pconsole',
    'pwStrength',
    'taboolaForm'
    ], function(i18n) {
        var ue,
            psr,
            psc,
            setupPasswordResetForm = function() {
               var id =  psc.length ? "#password" : '#pass1';
               $(id).pwStrength({
                  levels: i18n.user.passwordStrengthLevels
                });
            },
            initFormValidator = function() {
                ue.taboolaForm().taboolaForm("localize", PCONSOLE.validationMsgTranslator.user.errors);
            };

        $(document).ready(function() {
             psr = $('#passwordRestForm');
             psc = $('#passwordChangeForm');
            ue = (psr.length) ? psr : psc;

            /* self invocation */



            if(ue.length){
                if(psr.length || psc.length){
                    setupPasswordResetForm();
                }
                initFormValidator();
            }
         });

});

