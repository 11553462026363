define('l10nFactory',[
    'Intl'
], function () {

    function L10N(config) {
        this.config = config;

        try {
            this.formatNumber(1000);
        } catch(e) {
            this.requiresPolyfill = true;
        }

        this.locale = this.config.appLocale.replace('_', '-');
        this.config.decimalSymbol = config.decimalSymbol || '.';
        this.config.digitGroupingSymbol = config.digitGroupingSymbol || ',';
        this.numRegex = new RegExp("[^0-9-" + this.config.decimalSymbol + "]", ["g"]);
    }

    // Allow overriding the locale
    L10N.prototype = {
        get locale() {
            return this._locale;
        },
        set locale(newLocale) {
            var that = this;
            // yahooja and ja are defined in the same locale js file 'ja.js'
            this._locale = newLocale && newLocale.replace('yahooja', 'ja');

            /* Now - this is a tricky part: when setting the locale of this object,
             * we might need to load a polyfill, meaning that we will not be ready with
             * the new locale until the polyfill is loaded. so, we do a check and set a flag when ready.
             */
            this.isLocaleSet = !this.requiresPolyfill;

            // Load the locale (self-invoking jsonp file)
            require([this.config.appDomain + 'resources/components/Intl.js/locale-data/jsonp/' + this._locale + '.js'], function() {
                that.isLocaleSet = true;
            });
        }
    };

    // Format number with optional currency symbol, according to locale
    L10N.prototype.formatNumber = function (num, fractionDigits, currency) {
        var isNegative  = false,
            returnValue = null;

        num = typeof num !== 'number' ? parseFloat(num) : num;
        currency = typeof currency === 'string' ? currency : null;
        fractionDigits = typeof fractionDigits === 'number' ? fractionDigits : 0;

        if (isNaN(num)) {
            throw new TypeError('Cannot parse `num` parameter as Number');
        }

        if (!currency) {
            returnValue = num.toLocaleString(this._locale, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits
            });
        }
        else {
            if (num < 0) {
                isNegative = true;
                num = Math.abs(num);
            }

            returnValue = num.toLocaleString(this._locale, {
                style: "currency",
                currency: currency,
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits
            });

            // Here at Taboola we don't like standards so this is a hack to
            // replace the standard parenthesis around negative values with a
            // minus sign.
            if (isNegative) {
                returnValue = returnValue.replace(/([\d\.\s,]+)/, '-$1');
            }
        }

        return returnValue;
    };

    // A hack to get the appropriate symbol for a given currency code
    L10N.prototype.getCurrencySymbol = function (currency) {
        var tmp = this.formatNumber(0, 0, currency);

        return tmp.replace('0', '');
    };

    /**
     * Takes a formatted number string and returns unformatted number.
     * This method is copied from the accounting.js plugin:
     * https://github.com/openexchangerates/accounting.js
     * @param value
     * @returns {Number}
     */
    L10N.prototype.unformat = function(value) {
        return parseFloat(
            ("" + value)
                .replace(/\((.*)\)/, "-$1") // replace bracketed values with negatives
                .replace(this.numRegex, '')         // strip out any cruft
                .replace(this.config.decimalSymbol, '.')      // make sure decimal point is standard
        );
    };

    return {
        /**
         * NOTE: config properties are limited to primitive types
         */
        newInstance: function(config) {
            return new L10N(config);
        }
    };
});

