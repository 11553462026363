/**
 * Regional Data
 * en_us
 *
 * Dependencies: jquery
 *
 * @author Effie Nadiv
 * 12 Mars 2012
 * Copyright 2012, taboola.com
 */

define('validationMsgTranslator',[
    'messages'
], function (i18n) {
    window.PCONSOLE = window.PCONSOLE || {};
    PCONSOLE.validationMsgTranslator = {
        defaultMessages: {
            errors: {
                '[required]': i18n.client.error.required(),
                '[items-required]': i18n.client.error.itemsRequired(),
                '[maxlength]': i18n.client.error.maxlength(),
                '[data-type*=simplenum]': i18n.client.error.simplenum(),
                '[data-type*=floatnum]': i18n.client.error.floatnum(),
                '[data-type*=commanum]': i18n.client.error.commanum(),
                '[data-type*=wholenum]': i18n.client.error.wholenum(),
                '[numrange]': i18n.client.error.numrange(),
                '[comma-min-money]': i18n.client.error.comma_min_money(),
                '[data-type*=datefield]': i18n.client.error.datefield(),
                '[data-type*=items-list]': i18n.client.error.items_list(),
                '[min-date]': i18n.client.error.min_date(),
                '[max-date]': i18n.client.error.max_date(),
                '[date-before]': i18n.client.error.date_before(),
                '[date-after]': i18n.client.error.date_after(),
                '[max-decimal]': i18n.client.error.max_decimal(),
                '[smaller-than]': i18n.client.error.smaller_than(),
                '[larger-than]': i18n.client.error.larger_than(),
                '[min-value]': i18n.client.error.min_value(),
                '[max-value]': i18n.client.error.max_value(),
                '[data-type*=nospace]': i18n.client.error.nospace(),
                '[validate-closed-quotes]': i18n.client.error.closed_quotes(),
                '[validate-css-font]': i18n.client.error.missing_quotes_in_font_names(),
                '[data-type*=colortext]': i18n.client.error.colortext(),
                '[data-toggle=lil-collector]': i18n.client.error.lil_collector.required(),
                '[checkbox-required]': i18n.client.error.checkbox_required(),
                '[data-strength]': i18n.client.error.data_strength(),
                '[validate-items-in-list]': i18n.client.error.items_in_list(),
                '[require-one-of]': i18n.client.error.require_one_of(),
                '[validate-query-selector]': i18n.client.error.validate_query_selector(),
                'select[required]': i18n.client.error.select.required(),
                '[data-type*=url-with-semicolons]': i18n.client.error.invalid_url_with_semicolons(),
                '[table-items-required]': i18n.client.error.items_list()
            }
        },
        authenticationMethod: {
            errors: {
                '[minlength]': i18n.client.error.minlength_num(),
                '[maxlength]': i18n.client.error.maxlength_num(),
                '[pattern]': i18n.authenticationMethod.client.error.pattern()
            }
        },
        promotion: {
            errors: {
                '[name=endDate][required]': i18n.client.error.select.required(),
                '[name=startDate][required]': i18n.client.error.select.required()
            }
        },

        user: {
            errors: {
                '[name=passwordRetype][required]': i18n.user.client.error.passwordRetype.required(),
                '[name=passwordRetype][data-equals]': i18n.user.client.error.passwordRetype.data_equals(),
                '[name=pass2][required]': i18n.user.client.error.pass2.required(),
                '[name=pass2][data-equals]': i18n.user.client.error.pass2.data_equals(),
                '[name=username][required]': i18n.user.client.error.username.required(),
                '[name=phone][minlength]': i18n.client.error.minlength_num(),
                '[name=phone][pattern]': i18n.user.client.error.phone.pattern()
            }
        },
    };
});

