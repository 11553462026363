/**
 * Panels Toggler
 * This self invoking plugin allows switching between panels in a form using radio-like buttons.
 * Each panel contains form fields, displayed/hidden according to the relevant context selected.
 * The hiding / disabling of the fields is done using the dependsOn jquery plugin API.
 * jQuery plugin
 *
 * Usage:
 *
 * Option 1 - Self invocation:
 *      Add the following attributes to the field/container:
 *
 *      "data-related-toggler={fieldId}" - where {fieldId} is the Id of the hidden field of the radio button group
 *                                          that should control the visibility/enabling state of the subject element.
 *      "data-visible-for-panels={fieldValues}" / "data-enabled-for-panels={fieldValues}" (use either one of them, not both) -
 *                                          This defines the values of the hidden field stated in [data-related-toggler] for which the
 *                                          subject element is visible/enabled (correspondingly to the attribute name that was stated).
 *                                          The values should be separated by spaces.
 *
 *      Note that if your element is a container, and you are using [data-visible-for-panels] attribute,
 *      All of the element's children inputs will be disabled when the element is hidden, in order to skip validation.
 *
 * Option 2 - Manual invocation (use only if your element has been added to the DOM after the self invocation has already run):
 *      Add the attributes defined in "Option 1" to your elementm then call the constructor on your element, e.g.:
 *
 *      $(yourElement).panelsToggler();
 *
 *
 *
 *
 * NOTE: if you need to define more than one toggler on the same element (i.e., your field's visibility
 *      depends on more than one field), you can define the attributes of one toggler to your element,
 *      then wrap it with another container and apply the other toggler's rules to the container.
 *
 *      e.g.:
 *
 *      <div data-related-toggler="field1" data-visible-for-panels="value1 value2">
 *          <div data-related-toggler="field2" data-visible-for-panels="value3 value4">
 *              .
 *              .
 *              .
 *          </div>
 *      </div>
 *
 *
 * Dependencies: jquery, jqueryDependsOn
 *
 * 1 Dec 2013
 * Copyright 2013, taboola.com
 */

define(
    'panelsToggler',[
        'jquery',
        'jqueryDependsOn'
    ],
    function () {
    'use strict';

    var methods = {
        /**
         * Initalize toggler on the element and its children
         * @returns {*|jQuery|HTMLElement} - the toggled element
         */
        init: function() {
            var $this = $(this),
                relatedTogglerId = $this.data('related-toggler'),
                relatedTogglerSelector = '#' + relatedTogglerId,
                visibleForValues = $this.attr('data-visible-for-panels') ? $this.attr('data-visible-for-panels').split(' ') : [],
                enabledForValues = $this.attr('data-enabled-for-panels') ? $this.attr('data-enabled-for-panels').split(' ') : [],
                $dependsOn = $this.data('dependsOn'),
                dependsOnQualifiers = {},
                doHide = visibleForValues.length,
                dependsOnOptions = {duration: 0};

            /*
                Handle cases where user used both [data-visible-for-panels] and [data-enabled-for-panels].
                [data-visible-for-panels] is stronger and will be used by default
             */
            if(visibleForValues.length && enabledForValues.length) {
                $.warn('panelsToggler: container can have only one of the following attribute: "data-visible-for-panels" or "data-enabled-for-panels". The "enabled-for-panels" attribute will be ignored');
            }

            /*
                Add the current toggler to depnedsOn qualifiers.
                This will tell dependsOn that that field should be visible/enabled only if the value of the
                field that is defined in [data-related-toggler] equals one of the values stated in
                [data-visible-for-panels] / [data-enabled-for-panels].
             */
            dependsOnQualifiers[relatedTogglerSelector] = {values: (doHide ? visibleForValues : enabledForValues)};

            /*
                Tell dependsOn if it should hide the element on "illegal" values or not (depends on which attribute
                will be true only if the [data-visible-for-panels] attribute was used.
             */
            dependsOnOptions.hide = doHide;

            //Do not use dependsOn's disabling. We have our own implementation - see next
            dependsOnOptions.disable = false;

            /*
                Our own implementation of disabling the element -
                Used for supporting disabling of all children of the subject elements at once.
             */
            dependsOnOptions.onDisable = function() {
                var $affectedInputs = $this.is('input, select, button, textarea') ? $this : $this.find('input, select, button, textarea');

                $this.removeClass('selected-toggler');
                /*
                    Our own implementation of disabling the element - goes over all affected elements and disables them
                    If the subject element is a form element (input/select/button/textarea) - the affected input is the subject element.
                    If the subject element is a container - the affected inputs are all of its children of type input/select/button
                 */
                $affectedInputs.each(function() {
                    var $input = $(this),
                        $validationErrorDiv = $input.closest(".controls").find('.validation-error'),
                        currentDisablingPanels = $.trim($input.attr('data-disabled-by-panels')),
                        errorDataFieldName = $validationErrorDiv.find('span').attr('data-field-name'),
                        re = new RegExp('\\b' + relatedTogglerId + '\\b');

                    /*
                        If some other panel is disabling the field now (which is not me), simply add myself to the list of disabling panels and do nothing else.

                        If I am the only disabling panel, and the panel is not already disabled, disable it and indicate that I am disabling it
                        using the [data-disabled-by-panels] attribute. This will let other panels know not to enable it until I am letting it go.
                        Note that we do this only if the panel is not already disabled, since we do not want to interfere with form elements
                        That should be disabled for other reasons (e.g. defined as disabled in the view).
                     */
                    if(currentDisablingPanels && currentDisablingPanels !== relatedTogglerId && !re.test(currentDisablingPanels)) {
                        $input.attr('data-disabled-by-panels', currentDisablingPanels + ' ' + relatedTogglerId);
                    }else if (!$input.is('[disabled]')) {
                        $input.attr("disabled", "disabled").attr('data-disabled-by-panels', relatedTogglerId);

                        $input.trigger('disableStateChange', true);
                        //support acdropdown disabling
                        if($.fn.acdropdown && $input.is('select.autocomplete.replaced')) {
                            $input.acdropdown('disable');
                        }
                    }

                    if(errorDataFieldName && errorDataFieldName !== $input.attr('name')) {
                        return true;
                    }

                    /*
                        Remove validation errors related to the current field. they are not needed anymore since
                        this field is disabled now.
                     */
                    $validationErrorDiv.remove();
                    $input.closest(".control-group").removeClass("error");
                });

                /*
                 * If there aren't any invalid fields that are visible, remove the "validation error" fixed message
                 */
                if(!$(".error .validation-error").length) {
                    $('.fixed-message').hide();
                }
            };

            /*
                Our own implementation of enabling the element - goes over all affected inputs and enables them.
                If the subject element is a form element (input/select/button/textarea) - the affected input is the subject element.
                If the subject element is a container - the affected inputs are all of its children of type input/select/button
            */
            dependsOnOptions.onEnable = function() {
                var $affectedInputs = $this.is('input, select, button, textarea') ? $this : $this.find('input, select, button, textarea'),
                    re = new RegExp(relatedTogglerId, 'g');

                $this.addClass('selected-toggler');
                //Go over all children of subject element and enable them
                $affectedInputs.each(function() {
                    var $input = $(this),
                        currentDisablingPanels = $.trim($input.attr('data-disabled-by-panels'));

                    /*
                        If other panels (which are not me) are currently disabling the field, simply remove myself from the list
                        of disabling panel, and do nothing else.

                        If I am the only panel that is currently disabling the field - enable the field and remove the "disabled by me"
                        indication attribute ([data-disabled-by-panels]
                     */
                    if(currentDisablingPanels && currentDisablingPanels !== relatedTogglerId) {
                        $input.attr('data-disabled-by-panels', currentDisablingPanels.replace(re, '').replace('\\s+', ' '));
                    }else if(currentDisablingPanels === relatedTogglerId) {
                        $input.removeAttr('disabled').removeAttr('data-disabled-by-panels');

                        $input.trigger('disableStateChange', false);
                        //support acdropdown enabling
                        if($.fn.acdropdown && $input.is('select.autocomplete.replaced')) {
                            $input.acdropdown('enable');
                        }
                    }
                });


            };


            /*
                if the current field already has "dependsOn" definitions (meaning that it
                has previously defined field dependencies from outside), add myself to the rules using depnendsOn "or".
                Otherwise, call a new dependsOn constructor and add it to the element's data (so that other elements
                will be able to identify me as using depndsOn on the element).
             */
            if($dependsOn) {
                $dependsOn.or(dependsOnQualifiers, dependsOnOptions);
            }else {
                $this.data('dependsOn', $this.dependsOn(dependsOnQualifiers, dependsOnOptions));
            }

            return $this;

        }
    };


    /*
        Constructor - allows initializing the plugin manually on elements that were added
        to the DOM after the self invoking code has already run. usage: $(element).panelsToggler()
     */
    $.fn.panelsToggler = function(method) {
        if(!this || !this.length) {
            return;
        }

        // Method calling logic
        if (methods[method]) {
            return methods[ method ].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || ! method) {
            return methods.init.apply(this, arguments);
        }
        $.error('Method ' + method + ' does not exist on jQuery.panelsToggler');
    };

    //auto init existing elements having attribute [data-related-toggler]
    $('[data-related-toggler]').each(function() {
        methods.init.apply(this);
    });
});
