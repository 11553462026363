/**
 * Created with IntelliJ IDEA.
 * User: adi.d
 * Date: 19/07/15
 * Time: 12:24
 * To change this template use File | Settings | File Templates.
 */
define('stringUtils',[],
    function() {
        /* eslint no-extend-native: 0 */
        /**
         * general purpose substitute template
         *
         * 'hello {name}'.supplant({name: 'John Christiansen'}) --> hello John Christiansen
         */
        String.prototype.supplant = function (o) {
            return this.replace(/{([^{}]*)}/g,
                function (a, b) {
                    var r = o[b];
                    return typeof r !== 'undefined' ? r : a;
                }
            );
        };

        String.prototype.escape = function () {
            return this.replace(/&/g, "&amp;").
                replace(/>/g, "&gt;").
                replace(/'/g, "&apos;").
                replace(/"/g, "&quot;").
                replace(/</g, "&lt;");
        };

        String.prototype.unescape = function () {
            return this.replace(/&amp;/g, "&").
            replace(/&gt;/g, ">").
            replace(/&apos;/g, "'").
            replace(/&quot;/g, '"').
            replace(/&lt;/g, "<");
        };

        String.prototype.escapeRegEx = function() {
            return this.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
        };

        /**
         * Converts a number that represents time (in milliseconds) and returns a time string according to the given format.
         *
         * accepted time units: days (d), hours (h), minutes (m), seconds (s).
         * - The units should appear in the format string surrounded by {} (e.g. {h}).
         * - If the result should have leading zeros (e.g 03 instead of 3), the format should be {hh}.
         * - If the unit should not be displayed when its value equals 0, it should be surrounded by [] (e.g. [{hh}]).

         * for example: given the format "[{hh} hours] [{mm} min] {s}s", the function will return the following strings for the given values:
         *      5400000 will return "01 hours 30 min 00s"
         *      1803000 will return "30 min 3s"
         *      4000    will return "4s"
         *
         * @param format
         * @returns {string|*|String}
         */
        String.prototype.toTimeString = function(format) {
            var value = this,
                day = Math.floor(parseFloat(value / (1000 * 60 * 60 * 24))),
                hr = parseInt((value / (1000 * 60 * 60)) % 24, 10),
                min = parseInt((value / (1000 * 60)) % 60, 10),
                sec = parseInt((value / 1000) % 60, 10),
                dayStr = '' + day,
                dayStrWithLeadingZeros = (day < 10 && dayStr) ? '0' + dayStr : dayStr,
                hrStr = '' + hr,
                hrStrWithLeadingZeros = (hr < 10 && hrStr) ? '0' + hrStr : hrStr,
                minStr = '' + min,
                minStrWithLeadingZeros = (min < 10 && minStr) ? '0' + minStr : minStr,
                secStr = '' + sec,
                secStrWithLeadingZeros = (sec < 10 && secStr) ? '0' + secStr : secStr;

            //Search for units surrounded with []. This means that these units should be removed if their value equals 0
            if(day === 0) {
                format = format.replace(/(.*?)\[[^\]]*?\{dd?\}[^\]]*?\](.*?)/, '$1$2');
                if(hr === 0) {
                    format = format.replace(/(.*?)\[[^\]]*?\{hh?\}[^\]]*?\](.*?)/, '$1$2');
                    if(min === 0) {
                        format = format.replace(/(.*?)\[[^\]]*?\{mm?\}[^\]]*?\](.*?)/, '$1$2');
                        if(sec === 0) {
                            format = format.replace(/(.*?)\[[^\]]*?\{ss?\}[^\]]*?\](.*?)/, '$1$2');
                        }
                    }
                }
            }

            return format.replace(/[\[\]]/g, '').supplant({
                'dd': dayStrWithLeadingZeros,
                'd': dayStr,
                'hh': hrStrWithLeadingZeros,
                'h': hrStr,
                'mm': minStrWithLeadingZeros,
                'm': minStr,
                'ss': secStrWithLeadingZeros,
                's': secStr
            }).trim();
        };
    }
);

