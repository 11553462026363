!function( $ ){
	
	"use strict";
	
	var ACDropdown = function ( element, options ) {
			var that = this;
		
			options = $.extend({}, $.fn.acdropdown.defaults, options);
			$.fn.combobox.Constructor.apply(this, [element, options]);
		    this.isDropDownClick = false;
		    this.isAutoCompleteMode = false;
		    this.isMenuFocused = false;
		    this.isArrowNavScroll = false;
		    this.isSetPrevValPrevented = options.isSetPrevValPrevented;
		    this.isDisabled = false;
		    this.itemsRenderingBatchSize = options.itemsRenderingBatchSize || 25;
		    this.pendingItems = [];
		    
		    //mark original dropdown as replaced - CSS should set this selector with "display: none"
		    this.$source.addClass("replaced");
		    this.handleLabelClick();
            this.toPrevVal(); // Only if input is empty - it fills it with selected value
	};
		
	ACDropdown.prototype = $.extend({}, $.fn.combobox.Constructor.prototype, {

		constructor: ACDropdown,

        LABEL_FIELD: 'label',
        CLASS_FIELD: 'class',

		handleLabelClick: function() {
		    var that = this,
		    	targetId = that.$source.attr('id'),
		    	$label = that.$source.closest('.control-group').find('label[for=' + targetId + ']');
		    
		    $label.on('click', function() {
		    	that.$element.focus();
		    });

		}
		
		, select: function () {
			//This will prevent resetting the value after it was already selected (e.g after blur). This is meant for cases where selecting a value
			//is meant to navigate to another page and we do not want the value to be reset if focus is lost during new page load. 
			this.isSetPrevValPrevented = this.options.preventResetAfterSelect; 
			$.fn.combobox.Constructor.prototype.select.apply(this);
		}
		
		//Handler for dropdown button click
		, toggle: function () {
			var that = this,
				isFieldEmpty = this.$element.val() == '';
			
			if(this.isDisabled)
				return;
			
			//Clear value (only for when the "clear" button is in use).
			if (this.$container.hasClass('combobox-selected') && this.options.cancelButton) {
				this.toPrevVal();
			} else {
				//Open the menu in non-filtered mode. The second condition is for when the filtered list is already open and we want to extend to the non-filtered list.
				if (!this.shown || this.isAutoCompleteMode) {
					this.isAutoCompleteMode = false;
					this.lookup(true);
				} else {
					this.hide();
					//In case that the input field is empty, reset value to currently selected value
					if(isFieldEmpty) {
						this.toPrevVal();
					}
				}
				this.$element.focus();
			}
			
		    this.isDropDownClick = false;
		    
		    //Remove the text selection
		    /*if(!isFieldEmpty) {
				//deselect the text in the field. The usage of setTimeout is a workaround in order to make it work in Chrome.
		    	setTimeout(function () {that.removeTextSelection();}, 0);
		    }*/
		}
		
		//mouse down handler for dropdown button. we use this event and not "click" event because it comes before the input field "blur" event, and we want the flag to be set before.
		, toggleMouseDown: function () {
			this.isDropDownClick = true;
		}
		
		/*
		 * Handler for scrolling of menu - in case that the user have scrolls down the menu
		 * before all items were rendered, displays a loading animation, sets a delay and renders the next batch
		 */
		, menuScrollHandler: function(e) {
			var that = this;
			
			if(this.pendingItems && this.pendingItems.length) {
				//we have reached to the last visible item

				if(!this.isRendering && this.$menu.scrollTop() + this.$menu.height() >= this.$menu.prop('scrollHeight') - this.$menu.find('.loading-container').height()) {
					this.isRendering = true;

					if(this.renderTimeout) {
						clearTimeout(this.renderTimeout);
					}
					
					this.renderTimeout = setTimeout(function() {
						that.renderNextBatch();
					}, 300);
					
				}
			}else{ //no more items to render - remove loading animation
				this.$menu.find('.loading-container').remove();
				this.isRendering = false;
			}
		}
		
		/*
		 * Get index of first matching item in the complete list
		 */
		, getFirstMatchingItemIndex: function(items) {
			for(var i = 0; i < items.length; i++) {
				if(items[i].toLowerCase().indexOf(this.query.toLowerCase()) != -1) {
					return i;
				}
			}
		}
		
		/*
		 * In order to speed up the component in cases that there are many options in the list, we render the items in batches
		 * after every search. This function renders one batch of items and then calls another batch rendering
		 */
		, renderNextBatch: function() {
			var that = this;
			
			if(this.pendingItems && this.pendingItems.length) {
				this.render(this.pendingItems.slice(0, this.itemsRenderingBatchSize - 1));
				this.pendingItems = this.pendingItems.slice(this.itemsRenderingBatchSize - 1, this.pendingItems.length);

				this.$menu.append(this.$menu.find('.loading-container')); //add loading animation for next scroll
				this.isRendering = false;
				this.renderTimeout = setTimeout(function() {
					that.renderNextBatch();
				}); 
			}else {
				this.$menu.find('.loading-container').remove();
				this.isRendering = false;
				clearTimeout(this.renderTimeout);
			}
		}
		
            /*
		 * Keyboard event handlers. Here we override several key handlers for different functionality.
		 * In addition, when user is typing we add a delay (if defined) between key presses, because we do not
		 * want the filtering function to run for every key - it is consuming resources and slowing down the browser.
		 */
		, keyup: function(e) {
			var that = this;
			this.$element.addClass('typing');
			switch(e.keyCode) {
		        case 40: //Down arrow - open non-filtered list
		        	if(!this.shown) {
		        		this.lookup(true);
		        	}
		        	break;
		        case 27: //Escape - close menu and select input text
		        	this.exit();
		        	this.$element.select();
					break;
				//keys that should be handled by the original combobox function
				default:
					$.fn.combobox.Constructor.prototype.keyup.apply(this, [e]);
			}
		}

        // Clear element without focusing on it
        , clearElement: function () {
            this.$element.val('');
        }
		
		//Search for matches in the list. if skipFilter is set to false, the list will not be filtered (all result will be displayed). This is used for drop-down mode. 
		, lookup: function (skipFilter) {
			var that = this,
				items,
				q,
				itemsRenderingBatchSize = this.itemsRenderingBatchSize, //max number of items to render into the menu on each batch
				loadingContainer = $('<div class="loading-container"></div>');
					
			this.query = this.$element.val();
			this.pendingItems = [];
		  
			items = $.grep(this.source, function (item) {
				if (skipFilter || that.matcher(item)) {
					return item;
		        }
			});

			//We use sort only when in filtered mode
			items = skipFilter ? items : this.sorter(items);

			//Mark as "autocomplete" mode (as opposed to dropdown mode), so that we know if matching strings should be highlighted later
			this.isAutoCompleteMode = !skipFilter && this.$element.val() != '';
			
			if (!items.length) {
				return this.shown ? this.hide() : this;
			}
			
			this.$menu.html('');
			if(this.renderTimeout) {
				clearTimeout(this.renderTimeout);
			}
			
			//render result items in batches - to speed up component (menu will open after first batch render)
			if(items.length > this.itemsRenderingBatchSize) {
				if(!this.isAutoCompleteMode) { //If we are showing the complete list, we need to render also the first match, because we need to scroll to its position
					itemsRenderingBatchSize += this.getFirstMatchingItemIndex(items);
				}
				
				//render first batch and keep non rendered items in "pendingItems"
				this.pendingItems = items.slice(itemsRenderingBatchSize - 1, items.length);
				items = items.slice(0, itemsRenderingBatchSize - 1);
				
				this.renderTimeout = setTimeout(function() {
					that.renderNextBatch();
				}); 
			}
			
			this.render(items.slice(0, this.options.items), true).show();
			
			//add loading animation in case that not all items were rendered
			if(this.pendingItems && this.pendingItems.length) {
				loadingContainer.append('<div class="loading-indicator"></div>');
				this.$menu.append(loadingContainer);
			}
			
			//Scroll to the first matching item
			if(!this.isAutoCompleteMode) {
				var activeItem = this.$menu.find(".active").first();
				if(activeItem && activeItem.position()) {
					this.$menu.scrollTop(this.$menu.scrollTop() + this.$menu.find(".active").first().position().top);
				}
			}
			return this;
		}
		
		//Render the matching items as html <li> elements
		, render: function (items, markFirst) {
			var that = this,
				firstMarked;

			items = $(items).map(function (i, item) {
				var $itemEl = that.renderItem(item);

				//Mark first match as active (pre-selected)
				if(!that.isAutoCompleteMode && markFirst) {
					if(!firstMarked && $itemEl.attr('data-value').toLowerCase().indexOf(that.query.toLowerCase()) != -1) {
			        	$itemEl.addClass('active');
			        	firstMarked = true;
			        }
				}
				
				return $itemEl[0];
			});
		      
		    if(that.isAutoCompleteMode && markFirst) {
		    	items.first().addClass('active');
		    }
			
			this.$menu.append(items);

			return this;
		}

        , renderItem: function(item){
            var $itemEl,
                linkItem,
                item = item.toString();

            $itemEl = $(this.options.item).attr('data-value', item);

            //highlight matching string only in autocomplete mode. we escape the item inside highlighter
            linkItem = this.isAutoCompleteMode ? this.highlighter(item) : item.escape();
            $itemEl.find('span').html(linkItem);
            return $itemEl;
        }
		
		//This function does nothing. We want to override the original in order to prevent it from clearing the text field and navigating to a different page
		, clearTarget: function() {
			
		}
		
		//This function replaces the "clearTarget" function. It replaces the value in the input field with the current selected value
		, toPrevVal: function() {
			if(!this.isSetPrevValPrevented) {
				var origText = this.$source.children(":selected").text(),
					origVal = this.$source.children(":selected").val();
	 
				if(origText != this.$element.val() && !this.isDropDownClick) {
					this.$element.val(origText);
					this.$source.val(origVal).trigger('change');
				}
			}
		}
		
		//Add relevant event listeners
		, listen: function () {
			var ua = navigator.userAgent.toLowerCase();
			
			if(ua.indexOf("android") > -1 && ua.indexOf("mobile") > -1) { //in android we do not want the resize listener because it is dispatched every time that the keyboard is opened
				$(window)
				.on('resize', $.proxy(this.resize, this));
			}

			this.$element
				.on('focus',     $.proxy(this.focus, this));
	
			this.$menu
		        .on('mousedown', $.proxy(this.menuMouseDown, this));
	
			this.$menu
				.on('scroll', $.proxy(this.menuScrollHandler, this));
			
			this.$menu
	        	.on('touchstart', 'li', $.proxy(this.mouseenter, this));

			this.$menu
        		.on('touchend', 'li', $.proxy(this.mouseleave, this));
			
			this.$button
		        .on('mousedown', $.proxy(this.toggleMouseDown, this));
			
			//Add all other handlers from combobox component
			$.fn.combobox.Constructor.prototype.listen.apply(this);
		}

		//Hide menu on window resize. This prevents it from being misplaced on resize
		, resize: function () {
			if(this.shown) {
				this.hide();
			}
		}


		//Handle focus on input field
		, focus: function (e) {
			var that = this;
			//Select the text in the field. The usage of setTimeout is a workaround in order to make it work in Chrome.
			setTimeout(function () {that.$element.select();}, 0);
		}

		//Hanlder for input field blur
		, blur: function (e) {
            // this line is duplicate (see exit function) and shouldn't have been here. However, because of the 'no-blur' class for test purposes, we must have it also here
            this.$element.removeClass('typing');
            // In selenium parallel tests, this event is fired when windows lose focus, which cause tests to fail.
            if (this.$element.hasClass("no-blur")) {
                return;
            }
			e.stopPropagation();
			e.preventDefault();

			this.exit();
		}
		
		//Handler for menu mouse down - prevent input field from losing focus, otherwise menu will be closed
		, menuMouseDown: function () {
			var that = this;
			
			this.isMenuFocused = true;
			this.$element.focus();
		    //Remove the text selection
		    setTimeout(function () {
		    			that.removeTextSelection();
		    			that.isMenuFocused = false;
		    		}, 10);
		}
		
		//Handle down arrow press, moving to the next item
		, next: function (event) {
			$.fn.combobox.Constructor.prototype.next.apply(this, [event]);
			this.scrollToHiddenActive();
		}

		//Handle up arrow press, moving to the prev item
		, prev: function (event) {
			$.fn.combobox.Constructor.prototype.prev.apply(this, [event]);
			this.scrollToHiddenActive();
		}
		
		//Handle mouse enter on item, marks as active
		, mouseenter: function(event) {
			//This condition prevents the item from being selected when the mouse is over the menu during down/up arrows navigation and scrolling
			if(!this.isArrowNavScroll) {
				$.fn.combobox.Constructor.prototype.mouseenter.apply(this, [event]);
			}
			this.isArrowNavScroll = false;
		}
		
		//Close menu and reset input value
		, exit: function () {
			var that = this;
            this.$element.removeClass('typing');
			//Set the field to its original value
			if(!this.isMenuFocused && !this.$source.preventValReset) {
				this.toPrevVal();
			}
			
			//hide the menu, except for when the blur occurs due to dropdown button click (which is supposed to open the menu with the non-filtered list) or menu focus
			if (this.shown && !this.isDropDownClick && !this.isMenuFocused) {
				setTimeout(function () { that.hide(); }, 200);
				this.pendingItems = [];
				this.isRendering = false;
			}
				
			this.isDropDownClick = this.isMenuFocused = false;
		}
		
		//deselect the input text
		, removeTextSelection: function (input) {
			//IE - up to 8
			if (document.selection && document.selection.createRange) {
                var range = document.selection.createRange ();
                document.selection.empty ();
            //All other browsers
			}else if (window.getSelection) {
                var selection = window.getSelection ();
                if(selection) {
                	selection.removeAllRanges();
                }
	         }
			
			//Workaround for other browsers (e.g. Firefox) in case that the previous methods did not work
			var tmpVal = this.$element.val();
			this.$element.val("");
			this.$element.val(tmpVal);			
		}
		
		//Scroll menu to hidden items. Allows auto scrolling to items when using up/down arrows navigation
		, scrollToHiddenActive: function () {
			var activeItem = this.$menu.find('.active'),
			activeItemPos = activeItem.position().top,
			menuHeight = this.$menu.height(),
			targetPos = activeItemPos > 0 ? activeItemPos + activeItem.height() : activeItemPos,	//if item is below the menu, we need its position + height. otherwise, just position
			scrollDiff = targetPos > 0 ? targetPos - menuHeight  : targetPos,						//if item is below the menu the difference calculation should consider menu height.
			scrollPos = this.$menu.scrollTop();
			
			if(scrollDiff > 0 || targetPos < 0) {
				this.isArrowNavScroll = true; //set flag to prevent selection of incorrect item in case mouse is over the menu
				this.$menu.scrollTop(scrollPos + scrollDiff); //scroll to new position
			}
		}
		
		, disable: function () {
			this.isDisabled = true;
			this.$source.attr("disabled", "disabled");
			this.$element.attr("disabled", "disabled");
			this.$button.attr("disabled", "disabled");
		}
		
		, enable: function () {
			this.isDisabled = false;
			this.$source.removeAttr("disabled");
			this.$element.removeAttr("disabled");
			this.$button.removeAttr("disabled");
		}

        , setValue: function(newVal) {
            var newValOption = this.$source.find('option[value="' + newVal + '"]');

            if(newValOption.length) {
                this.$element.val(newValOption.text());
                this.$source.val(newVal).trigger('change');
                this.$target.val(newVal).trigger('change');
                this.$container.addClass('combobox-selected');
                this.selected = true;
            }
        }
        
        , transferAttributes: function() {
            $.fn.combobox.Constructor.prototype.transferAttributes.apply(this);

            this.$target.attr('required', this.$source.attr('required'));
            this.$target.attr('disabled', this.$source.attr('disabled'));
            this.$target.attr('data-disabled-for-panels', this.$source.attr('data-disabled-for-panels'));
		}
		
	});

	/* ACDropdown PLUGIN DEFINITION
	* =========================== */
	
	$.fn.acdropdown = function ( option ) {
		return this.each(function () {
			var $this = $(this),
	   		data, 
	   		options = typeof option == 'object' && option;
	   
	   	
	   	if (typeof option == 'string') {
	   		data = $this.data('acdropdown');
	   		data[option]();
	   	}else {
	   		$this.data('acdropdown', (data = new ACDropdown(this, options)));
	   	}
		});
	};

	$.fn.acdropdown.defaults = {
		cancelButton: false,
		template: '<div class="combobox-container"><input type="hidden" /><input type="text" /><span class="add-on btn dropdown-toggle" data-dropdown="dropdown"><span class="caret"/></span></div>',
		item: '<li><span></span></li>',
		preventResetAfterSelect: false
	};

	$.fn.acdropdown.Constructor = ACDropdown;

}( window.jQuery );

define("acdropdown", ["jquery","combobox"], function(){});

